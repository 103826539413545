import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import JwtPayloadViewer from '../../components/JwtPayloadViewer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <JwtPayloadViewer payload={{
      "identityscheme": "dkmitid",
      "sub": "{3687039b-97c3-4c67-aeb6-4da85f7e5818}",
      "address": {
        "formatted": "Paiman Petersen\nGrusgraven 1,3 tv\n3400 Hillerød\n(Lokalitet ukendt)\nDanmark",
        "common_name": "Paiman Petersen",
        "street_address": "Grusgraven 1,3 tv",
        "postal_code": "3400",
        "city": "Hillerød",
        "locality": "(Lokalitet ukendt)",
        "region": null,
        "country": "Danmark"
      },
      "address_details": {
        "road": "Grusgraven",
        "road_code": "1732",
        "municipality": "Lyngby-Taarbæk",
        "municipality_code": "0173",
        "house_number": "001",
        "floor": "03",
        "apartment_code": "  tv"
      },
      "uuid": "8e6ab4ea-58b9-4130-a900-4366ba54eed9",
      "cprNumberIdentifier": "2712589100",
      "cprNumberStatus": "01",
      "birthdate": "1958-12-27",
      "age": "63",
      "name": "Paiman Petersen",
      "country": "DK"
    }} mdxType="JwtPayloadViewer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      